<template>
    <div class="unit-add-edit">
        <!--顶部面包靴-->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/baseSetting/unitManagement' }" class="fs20">单位管理</el-breadcrumb-item>
            <el-breadcrumb-item class="fs20">{{ '单位新增' }}</el-breadcrumb-item>
        </el-breadcrumb>
        <!--表单-->
        <div class="mt30" style="margin-left: 30px">
            <div class="title">基本信息</div>
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="140px" class="demo-ruleForm">
                <el-form-item label="单位名称" prop="name">
                    <el-input clearable class="w230" placeholder="请输入单位名称" v-model="ruleForm.name"></el-input>
                </el-form-item>
                <el-form-item label="单位全称" prop="fullName">
                    <el-input clearable class="w230" placeholder="请输入单位全称" v-model="ruleForm.fullName"></el-input>
                </el-form-item>
                <el-form-item class="organCode" label="组织机构代码/统一社会信用代码" prop="socialCode" label-width="140px">
                    <el-input clearable class="w230" placeholder="请输入组织机构代码" v-model="ruleForm.socialCode"></el-input>
                </el-form-item>
                <el-form-item label="单位类别" prop="type">
                    <el-select clearable class="w230" v-model="ruleForm.type" @change="handlerChangeUnitType" placeholder="请选择">
                        <el-option v-for="(item, index) in unitTypeConfig" :key="index" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="单位性质" prop="characteristic">
                    <!--选企业显示-->
                    <el-select clearable v-if="ruleForm.type === 1" class="w230" v-model="ruleForm.characteristic" placeholder="请选择">
                        <el-option v-for="(item, index) in unitNatureCompany" :key="index" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                    <!--选政府显示-->
                    <el-select clearable v-else class="w230" v-model="ruleForm.characteristic" @change="handlerChangeNature" placeholder="请选择">
                        <el-option v-for="(item, index) in unitNatureGover" :key="index" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="单位所属系统" prop="systemId">
                    <el-select clearable class="w230" v-model="ruleForm.systemId" placeholder="请选择">
                        <el-option v-for="(item, index) in UNITSYSTEM" :key="index" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="单位通信地址" prop="contactAddress">
                    <el-input clearable class="w230" placeholder="请输入单位通信地址" v-model="ruleForm.contactAddress"></el-input>
                </el-form-item>
                <el-form-item label="单位联系人" prop="contactPerson">
                    <el-input clearable class="w230" placeholder="请输入单位联系人" v-model="ruleForm.contactPerson"></el-input>
                </el-form-item>
                <el-form-item label="单位联系人手机号" prop="contactPhone">
                    <el-input clearable class="w230" placeholder="请输入单位联系人手机号" v-model="ruleForm.contactPhone"></el-input>
                </el-form-item>
                <el-form-item label="单位联系电话" prop="unitPhone">
                    <el-input clearable class="w230" placeholder="请输入单位联系电话" v-model="ruleForm.unitPhone"></el-input>
                </el-form-item>
                <div class="title">政府监管</div>
                <el-form-item label="上级监管单位" prop="supervisedUnitTree">
                    <el-cascader
                        clearable
                        v-model="ruleForm.supervisedUnitTree"
                        :props="defaultProps"
                        class="w230"
                        ref="supervisedUnitCode"
                        :show-all-levels="false"
                        filterable
                        @change="handleSupervisedUnitCode"
                        :options="options"
                    ></el-cascader>
                </el-form-item>
                <el-form-item label="单位级别" prop="unitGrade">
                    <el-select clearable class="w230" v-model="ruleForm.unitGrade" placeholder="请选择">
                        <el-option v-for="(item, index) in UNITLEVEL" :key="index" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="行政区划" class="proviceItem" prop="proviceItem">
                    <!--默认显示到省级-->
                    <div>
                        <el-form-item label="所在国家" class="fl-left" label-width="80px" prop="guojia">
                            <el-select class="w230" v-model="ruleForm.guojia" placeholder="请选择">
                                <el-option label="中国" value="1"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="所在省" class="fl-left" label-width="80px" prop="provinceId">
                            <el-select clearable class="w230" v-model="ruleForm.provinceId" placeholder="请选择" @change="changeAddress($event, 'city')">
                                <el-option v-for="(item, index) in provinceConfig" :key="index" :label="item.organizationName" :value="item.organizationId"></el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                    <div v-if="ruleForm.unitGrade">
                        <el-form-item v-if="ruleForm.unitGrade >= 2" prop="cityId" label="所在市" class="fl-left" label-width="80px">
                            <el-select clearable class="w230" v-model="ruleForm.cityId" placeholder="请选择" @change="changeAddress($event, 'area')">
                                <el-option v-for="(item, index) in cityConfig" :key="index" :label="item.organizationName" :value="item.organizationId"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item v-if="ruleForm.unitGrade >= 3" prop="areaId" label="所在区县" class="fl-left" label-width="80px">
                            <el-select clearable class="w230" v-model="ruleForm.areaId" placeholder="请选择" @change="changeAddress($event, 'street')">
                                <el-option v-for="(item, index) in areaConfig" :key="index" :label="item.organizationName" :value="item.organizationId"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item v-if="ruleForm.unitGrade >= 4" prop="streetId" label="所在街道" class="fl-left" label-width="80px">
                            <el-select clearable class="w230" v-model="ruleForm.streetId" placeholder="请选择" @change="changeAddress($event, 'community')">
                                <el-option v-for="(item, index) in streetConfig" :key="index" :label="item.organizationName" :value="item.organizationId"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item v-if="ruleForm.unitGrade >= 5" prop="communityId" label="所在社区" class="fl-left" label-width="80px">
                            <el-select clearable class="w230" v-model="ruleForm.communityId" placeholder="请选择" @change="changeAddress($event, 'clickCommunity')">
                                <el-option v-for="(item, index) in communityConfig" :key="index" :label="item.organizationName" :value="item.organizationId"></el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                </el-form-item>
                <el-form-item v-if="ruleForm.type === 1" label="上级企业单位" prop="parentEnterpriseName">
                    <el-input
                        readonly
                        suffix-icon="el-icon-search"
                        clearable
                        class="w230"
                        placeholder="请选择"
                        v-model="ruleForm.parentEnterpriseName"
                        @focus="openParentDilog"
                    ></el-input>
                </el-form-item>
                <div class="title">账号信息</div>
                <el-form-item label="账号" prop="userName">
                    <el-input clearable class="w230" placeholder="请输入账号" v-model="ruleForm.userName"></el-input>
                </el-form-item>
                <el-form-item label="密码" prop="passWord">
                    <el-input show-password clearable class="w230 password" placeholder="请输入密码" v-model="ruleForm.passWord"></el-input>
                </el-form-item>
                <el-form-item label="确认密码" prop="confirmPassword">
                    <el-input show-password clearable class="w230" placeholder="请确认密码" v-model="ruleForm.confirmPassword"></el-input>
                </el-form-item>
                <el-row type="flex" justify="center">
                    <el-col :offset="6" :span="10">
                        <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
                        <el-button @click="$router.go(-1)">取 消</el-button>
                    </el-col>
                </el-row>
            </el-form>
        </div>
        <paraentEnterDilog :mytype="ruleForm.type" @checkedItem="checkedItem" :paraentEnterVisible.sync="paraentEnterVisible" />
    </div>
</template>

<script>
import {
    UNITCATEGORY, //单位类别
    UNITNATURE_ENTERPRISE, //单位性质企业
    UNITNATURE_GOVERNMENT, //单位性质政府
    UNITSYSTEM, //单位所属系统
    UNITLEVEL //单位级别
} from './dataDictionary'
import { RULES } from './ruleForm'
import { getSuperiorGovernmentList, getChildrenDict, addPensionUnit } from '@/api/unitManage'
import paraentEnterDilog from './components/paraentEnterDilog'
export default {
    name: 'unitAdd',
    components: {
        paraentEnterDilog
    },
    data() {
        return {
            paraentEnterVisible: false, //选择上级企业单位弹框
            unitTypeConfig: UNITCATEGORY, //单位类别
            unitNatureCompany: [], //单位性质（企业）
            unitNatureGover: [], //单位性质（政府）
            // unitLevelConfig: UNITLEVEL, //单位级别
            ruleForm: {
                name: '', //单位名称
                fullName: '', //单位全称
                socialCode: '', //社会统一代码
                type: '', //单位类别
                characteristic: '', //单位性质
                systemId: '', //单位所属系统
                contactAddress: '', //单位通信地址
                contactPerson: '', //单位联系人
                contactPhone: '', //单位联系人手机号
                unitPhone: '', //单位联系电话
                supervisedUnitCode: '', //上级监管单位
                supervisedUnitTree: [], //上级监管单位选中的结果 需删除
                unitGrade: '', //单位级别
                provinceId: '', //省级Id
                province: '', //省名称
                cityId: '', //市Id
                city: '', //市名称
                areaId: '', //区Id
                area: '', //区名称
                streetId: '', //街道id
                street: '', //街道名称
                communityId: '', //社区id
                community: '', //区名称
                parentEnterpriseId: '', //上级企业单位
                parentEnterpriseName: '', //上级企业单位名称，需删除
                userName: '', //账号
                passWord: '', //密码
                confirmPassword: '', //确认密码
                guojia: '1',
                proviceItem: '' //控制校验的 删除
            },
            rules: RULES,
            supervisedUnitCodeType: '', //选择的上级监管单位type
            defaultProps: {
                value: 'id',
                label: 'name',
                children: 'superiorGovernmentDtoList',
                checkStrictly: true
            },
            parentEnterprisedefaultProps: {
                //上级企业单位
                value: 'name',
                label: 'name',
                checkStrictly: true
            },
            parentEnterpriseOptions: [],
            options: [],
            provinceConfig: [], //省配置
            cityConfig: [], //市配置
            areaConfig: [], //区配置
            streetConfig: [], //街道配置
            communityConfig: [] //社区配置
        }
    },
    computed: {
        UNITSYSTEM() {
            //所属系统
            if (this.ruleForm.type && this.ruleForm.type === 1) {
                //选择了单位类别
                return UNITSYSTEM.filter(item => item.value >= 3)
            } else if (this.ruleForm.type && this.ruleForm.type === 2) {
                if (this.ruleForm.characteristic && this.ruleForm.characteristic <= 4) {
                    return UNITSYSTEM.filter(item => item.value === 1)
                } else if (this.ruleForm.characteristic && this.ruleForm.characteristic >= 4) {
                    return UNITSYSTEM.filter(item => item.value === 2)
                } else {
                    return []
                }
            } else {
                //未选择
                return []
            }
        },
        //  单位级别
        UNITLEVEL() {
            if (this.ruleForm.systemId === 4) {
                // 若单位所属系统是医疗卫生系统时：不可选择乡镇（街道）级以上的单位级别。
                return UNITLEVEL.filter(item => item.value >= 4)
            } else {
                //当单位类别是企业时：单位级别可以选上级监管单位的级别及下级
                if (this.ruleForm.type === 1) return UNITLEVEL.filter(item => item.value >= this.supervisedUnitCodeType)
                // 当单位类别是政府时：单位级别只能选上级监管单位的下级
                if (this.ruleForm.type === 2) return UNITLEVEL.filter(item => item.value > this.supervisedUnitCodeType)
            }
        }
    },
    mounted() {
        this.getAddressData('', 'province')
        setInterval(function () {
            // 解决级联选择器不点击圆点选中元素问题
            document.querySelectorAll('.el-cascader-node__label').forEach(el => {
                el.onclick = function () {
                    if (this.previousElementSibling) this.previousElementSibling.click()
                }
            })
        }, 1000)
    },
    methods: {
        //  单位类别切换
        handlerChangeUnitType(type) {
            if (type === 1) {
                this.unitNatureCompany = UNITNATURE_ENTERPRISE
            } else if (type === 2) {
                this.unitNatureGover = UNITNATURE_GOVERNMENT
                //切换成企业清空上级企业单位信息
                this.ruleForm.parentEnterpriseId = ''
                this.ruleForm.parentEnterpriseName = ''
            } else {
                this.unitNatureGover = []
                //切换成企业清空上级企业单位信息
                this.ruleForm.parentEnterpriseId = ''
                this.ruleForm.parentEnterpriseName = ''
            }
            this.ruleForm.characteristic = '' //切换类型清空单位性质
            this.ruleForm.systemId = '' //切换类型清空所属单位性质
            this.ruleForm.unitGrade = '' //切换清空单位级别
            this.ruleForm.supervisedUnitTree = [] //清空上级监管单位
            this.getSuperiorGovernmentListData() //选择单位类别切换 上级监管单位
        },
        //  单位性质切换
        handlerChangeNature() {
            this.ruleForm.systemId = ''
        },
        //  查询上级监管单位 tree数据
        async getSuperiorGovernmentListData() {
            if (!this.ruleForm.type) return
            const res = await getSuperiorGovernmentList({ type: this.ruleForm.type })
            if (res.state === 0 && res.data instanceof Array) {
                this.options = res.data
            }
        },
        //  选择上级监管单位
        handleSupervisedUnitCode() {
            this.ruleForm.unitGrade = '' //清空单位等级
            let info = this.$refs['supervisedUnitCode'].getCheckedNodes()
            if (info[0].data.unitGrade <= 6) this.supervisedUnitCodeType = info[0].data.unitGrade
            if (this.ruleForm.type === 2 && info[0].data.unitGrade === 5)
                return this.$message.warning('您选择的上级监管单位的单位级别为村(社区)级，不可创建村(社区)级的下级政府单位！')
        },
        //  打开选择上级企业单位弹框
        openParentDilog() {
            this.paraentEnterVisible = true
        },
        //  选择上级企业单位弹框返回数据
        checkedItem(data) {
            this.ruleForm.parentEnterpriseId = data.parentEnterpriseId
            this.ruleForm.parentEnterpriseName = data.parentEnterpriseName
        },
        //  切换查下级地址
        changeAddress(id, type) {
            if (type !== 'clickCommunity') this.getAddressData(id, type)
            else this.ruleForm.community = this.getAddressName(id, this.communityConfig) //选择社区 拿到社区名字
        },
        //  获取地址名称
        getAddressName(id, data) {
            let name = JSON.parse(JSON.stringify(data)).filter(item => item.organizationId === id)
            console.log(name[0].organizationName)
            return name[0].organizationName
        },
        //  获取地址
        async getAddressData(id, type) {
            let params = {
                pid: id ? id : ''
            }
            if (!id) Reflect.deleteProperty(params, 'pid')
            const res = await getChildrenDict(params)
            if (res.state === 0) {
                switch (type) {
                    case 'province': //初始化省
                        this.provinceConfig = res.data
                        break
                    case 'city': //选择省查市
                        this.cityConfig = res.data
                        this.ruleForm.cityId = ''
                        this.ruleForm.city = ''

                        this.ruleForm.areaId = ''
                        this.ruleForm.area = ''

                        this.ruleForm.streetId = ''
                        this.ruleForm.street = ''

                        this.ruleForm.communityId = ''
                        this.ruleForm.community = ''

                        this.ruleForm.province = this.getAddressName(id, this.provinceConfig) //选择省 拿到省名字
                        break
                    case 'area': //选择市查区
                        this.areaConfig = res.data
                        this.ruleForm.areaId = ''
                        this.ruleForm.area = ''

                        this.ruleForm.streetId = ''
                        this.ruleForm.street = ''

                        this.ruleForm.communityId = ''
                        this.ruleForm.community = ''

                        this.ruleForm.city = this.getAddressName(id, this.cityConfig) //选择市 拿到市名字
                        break
                    case 'street': //选择区查街道
                        this.streetConfig = res.data
                        this.ruleForm.streetId = ''
                        this.ruleForm.street = ''

                        this.ruleForm.communityId = ''
                        this.ruleForm.community = ''

                        this.ruleForm.area = this.getAddressName(id, this.areaConfig) //选择区 拿到区名字
                        break
                    case 'community': //选择区查街道
                        this.communityConfig = res.data

                        this.ruleForm.communityId = ''
                        this.ruleForm.community = ''

                        this.ruleForm.street = this.getAddressName(id, this.streetConfig) //选择街道 拿到街道名字
                        break
                }
            }
        },
        submitForm(formName) {
            this.$refs[formName].validate(valid => {
                if (valid) {
                    let params = JSON.parse(JSON.stringify(this.ruleForm))
                    params.supervisedUnitCode = params.supervisedUnitTree[params.supervisedUnitTree.length - 1]
                    Object.keys(params).map(item => {
                        if (['', null, undefined].includes(params[item])) {
                            Reflect.deleteProperty(params, item)
                        }
                    })
                    Reflect.deleteProperty(params, 'supervisedUnitTree')
                    this.addPensionUnitPost(params)
                } else {
                    console.log('error submit!!')
                    return false
                }
            })
        },
        resetForm(formName) {
            this.$refs[formName].resetFields()
        },
        async addPensionUnitPost(params) {
            const res = await addPensionUnit(params)
            if (res.state === 0) {
                this.$message.success(res.data)
                this.$router.go(-1)
                this.resetForm('ruleForm')
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.unit-add-edit {
    background-color: #ffffff;
    padding: 20px;
    /deep/ .organCode > .el-form-item__label {
        line-height: 20px !important;
    }
    /deep/ .proviceItem {
        margin-bottom: 12px !important;
    }
    .password {
        position: relative;
    }
    .password:after {
        content: '密码必须是8-16位大小写英文字母、数字、字符组合。 在设置时，尽量不要出现连续数字的情况。';
        width: 400px;
        height: 60px;
        position: absolute;
        left: 245px;
        font-size: 16px;
        line-height: 20px;
        font-family: PingFangSC-Regular, PingFang SC;
        color: #666666;
    }
    .fs20 {
        font-size: 14px;
    }
    .mt30 {
        margin-top: 30px;
    }
    .w230 {
        width: 230px;
    }
    .provice {
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        padding-right: 20px;
        display: inline-block;
        span {
            display: inline-block;
            padding-right: 20px;
        }
    }
    .fl-left {
        float: left;
        margin-right: 20px;
        margin-bottom: 20px;
    }
    .title {
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        margin-bottom: 20px;
    }
}
</style>
